@import "~rivals/styles/breakpoints";
@import "~rivals/styles/hide-elements";
@import "~rivals/styles/mixins";
@import "~rivals/styles/variables";

$team-img-height: 80px;
$team-img-width: $team-img-height;
$team-img-mobile-height: 43px;
$team-img-mobile-width: $team-img-mobile-height;

$athlete-bio-spacing: $spacing-24;

$head-shot-dimensions: 85px;
$sm-team-logo-dimensions: 34px;

@mixin card-related-variant(
  $small-team-logo-dimensions,
  $profile-img-info-spacing
) {
  align-items: center;
  margin-bottom: 0;

  .imageContainer {
    .teamLogo {
      height: $small-team-logo-dimensions;
      width: $small-team-logo-dimensions;
    }
  }

  .info {
    margin-left: $profile-img-info-spacing;

    .name {
      margin-bottom: 10px;
    }

    .teamLogoBg {
      height: unset;
      left: 50%;
      pointer-events: none;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 300px;
    }
  }
}

.mobileName {
  @include hide-on-tablet-and-desktop;
  @include sizes-ending-with($screen-lg-mobile-max) {
    @include font-yahoo-h25;
    color: $white;
    text-align: center;
  }
}

.name {
  @include font-yahoo-h1;
  color: $white;
  font-size: 60px;
  margin-bottom: $spacing-12;
}

.personalDataContainer {
  display: flex;
  margin-bottom: $spacing-24;

  &.cardVariant {
    @include card-related-variant($sm-team-logo-dimensions, $spacing-12);

    .info .name {
      @include font-yahoo-h3;
    }
  }

  &.socialCardVariant {
    @include card-related-variant(96px, $spacing-48);

    .imageContainer .profileImg {
      border-radius: 50%;
    }

    .info {
      .name {
        @include font-yahoo-h0;
        font-size: 6vw;
      }

      .teamLogoBg {
        height: 500px;
        width: 500px;
      }
    }
    @include sizes-ending-with($athlete-embed-card-min) {
      @include card-related-variant(32px, 8px);

      .info {
        .name {
          @include font-yahoo-h25;
          font-size: 6vw;
          margin-bottom: $spacing-4;
        }

        .teamLogoBg {
          height: 150px;
          width: 150px;
        }
      }
    }
  }

  .imageContainer {
    @include sizes-ending-with($screen-lg-mobile-max) {
      margin: $spacing-12 0;
    }
    position: relative;

    .teamLogo {
      bottom: 0;
      height: $team-img-height;
      position: absolute;
      right: 0;
      width: $team-img-width;
    }
  }

  .info {
    margin-left: $spacing-24;

    .teamLogoBg {
      height: 206px;
      opacity: 0.1;
      position: absolute;
      right: 121px;
      top: 0;
      width: 206px;
      z-index: -1;
    }
  }

  &.pageVariant {
    @include sizes-ending-with($screen-lg-mobile-max) {
      align-items: center;
      flex-direction: column;
    }

    .imageContainer {
      .teamLogo {
        @include sizes-ending-with($screen-lg-mobile-max) {
          height: $team-img-mobile-height;
          margin: $spacing-12 0;
          width: $team-img-mobile-width;
        }
      }
    }

    .info {
      @include sizes-ending-with($screen-lg-mobile-max) {
        margin-left: 0;
        text-align: center;
      }

      .name {
        @include hide-on-mobile;
      }

      .teamLogoBg {
        @include sizes-between($screen-tablet-min, $screen-tablet-max) {
          right: $athlete-bio-spacing;
        }
        @include sizes-ending-with($screen-lg-mobile-max) {
          display: none;
        }
      }
    }
  }
}
