@import "~rivals/styles/breakpoints.scss";
@import "~rivals/styles/colors.scss";
@import "~rivals/styles/hide-elements.scss";
@import "~rivals/styles/typography.scss";
@import "~rivals/styles/variables.scss";

$profile-img-height: 200px;
$profile-img-width: $profile-img-height;
$profile-img-mobile-height: 142px;
$profile-img-mobile-width: $profile-img-mobile-height;

.personalStats {
  display: flex;
  flex-direction: column;

  &.cardVariant .personalStatRow,
  &.socialCardVariant .personalStatRow {
    @include font-yahoo-b5;
    margin-bottom: 0;
  }

  &.socialCardVariant .personalStatRow {
    @include font-yahoo-b1;
    font-size: 3.4vw;
    @include sizes-ending-with($athlete-embed-card-min) {
      @include font-yahoo-b4;
      font-size: 3.4vw;
    }

    .point {
      @include hide-on-mobile;
    }
  }

  .personalStatRow {
    margin-bottom: $spacing-12;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .point {
    margin: 0px ($spacing-4 * 2);
  }

  .column {
    margin: 0 3px;
  }

  &.pageVariant {
    .personalStatRow .positionName {
      @include sizes-ending-with($screen-lg-mobile-max) {
        display: block;
        margin-bottom: $spacing-12;
      }
    }

    .heightWeight {
      display: inline-block;
    }

    .point {
      @include hide-on-mobile;
    }
  }
}
