@import "~rivals/styles/breakpoints.scss";
@import "~rivals/styles/typography.scss";
@import "~rivals/styles/variables.scss";

.bioContainer {
  align-items: center;
  display: flex;
  text-transform: uppercase;
  z-index: 1;

  .personalData {
    align-items: center;
    margin: 0;
  }
}
